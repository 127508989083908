// @flow
import * as React from 'react';
import styled from '@emotion/styled';

import { color } from './constants';

import Arrow from './Arrow';

const background = ({ useBackground, textColor }) =>
  useBackground &&
  `background: ${
    textColor === color.brandSecondary
      ? color.brandPrimary
      : color.brandSecondary
  };
  padding: 0.75rem 1rem 0.75rem 1.75rem;
  border: 1px solid ${color.brandPrimary};
  &:focus {
    border-color: ${color.beige};
  }
`;

const Button = styled('button')`
  margin: 0;
  padding: 0;
  border: 0;
  font-size: ${17 / 16}rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15rem;
  &:hover > span {
    border-bottom: ${({ useBackground }) =>
      !useBackground && `1px solid ${color.brandPrimary}`};
  }
  > span {
    margin-left: 0.5rem;
  }
  color: ${({ textColor }) => textColor};
  outline: none;
  cursor: pointer;
  ${({ useBackground, textColor }) => background({ useBackground, textColor })};
`;

const A = ({
  children,
  arrowDown,
  href,
  target,
  handleClick = null,
  style,
  textColor,
}: {
  children: React.Node,
  arrowDown?: boolean,
  href?: string,
  target?: string,
  handleClick: Function,
  style?: Object,
  textColor?: string,
}) => (
  <Button
    as={handleClick ? 'button' : 'a'}
    href={href}
    textColor={textColor}
    style={style}
    type="button"
    onClick={handleClick}
    useBackground={!!handleClick}
    target={target}
  >
    <Arrow down={arrowDown} color={textColor} />
    <span>{children}</span>
  </Button>
);
A.defaultProps = {
  arrowDown: false,
  href: '#',
  style: {},
  textColor: color.brandPrimary,
  target: '',
};

export default A;
