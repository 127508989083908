import React, { Component } from 'react';
import styled from '@emotion/styled';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { pxToRem, getBackground } from './helpers';
import { breakpoint, color } from './constants';

import ArrowSmall from './ArrowSmall';

const Container = styled('div')`
  margin-bottom: 2.25rem;

  &.furniture {
    display: none;
  }
  
  > .CustomMuiInputLabel {
    display: block;
    margin-bottom: 1.25rem;
    font-weight: 500;
    font-size: ${pxToRem(20)};
    text-transform: uppercase;
    color: ${color.brandPrimary};
  }
  > .CustomMuiFormControl > div {
    border: 1px solid ${color.brandPrimary};
    &[class*='MuiInputBase-disabled'] {
      border: 1px solid ${color.darkGray};
      cursor: not-allowed;
    }
    &::before {
      border-bottom: 0 solid ${color.brandPrimary};
    }
    > .CustomMuiSelect {
      > [class*='MuiSelect-disabled'] {
        cursor: not-allowed;
      }
      > div {
        height: ${pxToRem(46)};
        min-height: 3rem;
        padding-left: 0.5rem;
        line-height: 2.2;
        box-sizing: border-box;
        @media only screen and (min-width: ${breakpoint.md}) {
          min-height: auto;
        }
      }
      &.is-open svg {
        transform: translateY(-30%) rotateZ(360deg) rotateX(180deg);
      }
    }
  }
`;

const Color = styled('div')`
  display: inline-block;
  width: ${pxToRem(33)};
  height: ${pxToRem(33)};
  margin-right: 0.5rem;
  box-sizing: border-box;
  border: 1px solid ${color.brandPrimary};
  background: ${({ bgColor }) => getBackground(bgColor)};
  opacity: ${({ disabled }) => disabled && 0.3};
`;

const Label = styled('span')`
  margin-top: 0.1rem;
  vertical-align: top;
`;

class Config extends Component<{
  name: string,
  title: string,
  currentValue: string,
  handleChange: Function,
  options: Object,
  disabled?: boolean,
}> {
  static defaultProps = {
    disabled: false,
  };

  state = {
    selectOpen: false,
  };

  handleClose = () => {
    this.setState({ selectOpen: false });
  };

  handleOpen = () => {
    this.setState({ selectOpen: true });
  };

  render() {
    const { name, title, currentValue, handleChange, options } = this.props;
    let { disabled } = this.props;
    disabled = disabled || 2 > Object.keys(options).length;
    const { selectOpen } = this.state;
    const hasColor = name.endsWith('Color');
    return (
      <Container className={name}>
        <InputLabel
          disabled={disabled}
          htmlFor={name}
          classes={{ root: 'CustomMuiInputLabel' }}
        >
          {title}
        </InputLabel>
        <FormControl
          style={{ width: pxToRem(203) }}
          classes={{ root: 'CustomMuiFormControl' }}
          disabled={disabled}
        >
          <Select
            value={currentValue || ''}
            open={selectOpen}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            onChange={({ target }) => handleChange(target)}
            name={name}
            inputProps={{
              id: name,
            }}
            classes={{ root: `CustomMuiSelect${selectOpen ? ' is-open' : ''}` }}
            IconComponent={() => ArrowSmall({ disabled })}
          >
            {Object.entries(options).map(([option, label]) => (
              <MenuItem key={option} value={option}>
                {hasColor && <Color bgColor={option} disabled={disabled} />}
                <Label>{label}</Label>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Container>
    );
  }
}

export default Config;
