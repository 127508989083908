// @flow
/* eslint-disable no-underscore-dangle */
import {
  products,
  color,
  remBaselinePx,
  remMobilePx,
  imgBankUrl,
} from './constants';

// Polyfills
require('core-js/fn/object/keys');
require('core-js/fn/object/values');
require('core-js/fn/object/entries');
require('core-js/fn/array/includes');
require('core-js/fn/string/includes');
require('core-js/fn/string/ends-with');
require('core-js/fn/string/starts-with');

/**
 * Get provided px as rem value.
 *
 * @param   {string} px Pixel value.
 *
 * @returns {string}    Rem value.
 */
export const pxToRem = (px: number) => `${px / remBaselinePx}rem`;

/**
 * Get provided rem as a mobile equivalent in side.
 *
 * @param   {string} rem Rem value.
 *
 * @returns {string}     Rem value.
 */
export const remToMobile = (rem: number) =>
  `${(remBaselinePx / remMobilePx) * rem}rem`;

export const stripProductPrefixes = (passedString: string) => {
  let scopedString = passedString;
  products.forEach((product) => {
    scopedString = scopedString.replace(`${product}_`, '');
  });
  return scopedString;
};

/**
 * Get background color for selection dropdowns.
 *
 * @param   {string} passedColor Passed color.
 *
 * @returns {string}             Formatted background.
 */
export const getBackground = (passedColor: string) => {
  let background = '';
  const scopedColor = stripProductPrefixes(passedColor);
  const resolvedColor = color[scopedColor] || scopedColor;
  switch (true) {
    case !resolvedColor.length: {
      background = color.white;
      break;
    }
    case resolvedColor.constructor === String &&
      resolvedColor.startsWith('SY'): {
      background = `url(${imgBankUrl}/textiles/${scopedColor}.jpg)`;
      break;
    }
    case resolvedColor.constructor === String: {
      background = resolvedColor;
      break;
    }
    case resolvedColor.constructor === Array && 2 <= resolvedColor.length: {
      background = `linear-gradient(to bottom right, ${resolvedColor[0]} 50%, ${resolvedColor[1]} 50%)`;
      break;
    }
    default: {
      background = color.white;
    }
  }
  return background;
};

const lang = (
  (document.documentElement && document.documentElement.lang) ||
  ''
).substr(0, 2);
let translations = process.env.REACT_APP_TRANSLATIONS;
try {
  translations = JSON.parse(translations);
} catch (e) {
  translations = {};
  console.error('Failed to parse translations JSON!');
}
/**
 * Translate
 *
 * @param   {string} text String to translate.
 *
 * @returns {string}      Translated string.
 */
export const __ = (text: string) => {
  if (translations[text] && translations[text][lang]) {
    return translations[text][lang];
  }
  return text;
};

/**
 * Update browser url.
 *
 * @param {object} data
 * @param {string} data.product    Product
 * @param {string} data.selections Configuration selections
 *
 * @returns {string} Current url after update.
 */
export const updateUrl = ({
  product,
  selections,
}: {
  product: string,
  selections: Object,
}) => {
  const selectionData = Object.entries(selections).reduce(
    (acc, [key, value]) => `${acc},${key}:${value}`,
    ''
  );
  const url = `${window.location.origin}${window.location.pathname}`;
  const newUrl = selectionData
    ? `${url}?product=${product}&selections=${selectionData.slice(1)}`
    : url;
  if (window.history.pushState && newUrl !== window.location.href) {
    window.history.pushState({ path: newUrl }, '', newUrl);
  }
  return { url: newUrl }; // Might be old url.
};

/**
 * Filter config options by product.
 *
 * @param {object} data
 * @param {object} data.configs Configs
 * @param {string} data.product Product
 *
 * @returns {object} Object with filteredConfigs as only param,
 *                   where options are compatible with the passed product.
 */
export const filterConfigOptions = ({
  configs,
  product,
}: {
  configs: Object,
  product: string,
}) => {
  const filteredConfigs = {};
  Object.entries(configs).forEach(([key, config]) => {
    const filteredConfig = { ...config };
    filteredConfig.options = Object.keys(filteredConfig.options).reduce(
      (filteredOptions, option) => {
        let removeOption = false;
        // Check if option is prefixed by product name.
        products.some((scopedProduct) => {
          const optionNotUsable =
            option.includes(scopedProduct) && !option.includes(product);
          if (optionNotUsable) {
            removeOption = true;
          }
          return optionNotUsable;
        });
        return removeOption
          ? filteredOptions
          : {
              ...filteredOptions,
              [option]: filteredConfig.options[option],
            };
      },
      {}
    );
    filteredConfigs[key] = filteredConfig;
  });
  return { filteredConfigs };
};

/**
 * Parse configurator data from url.
 *
 * @returns {object} Product and selections.
 */
export const parseUrl = ({
  configs,
  defaultProduct,
}: {
  configs: Object,
  defaultProduct: string,
}): { product: string, selections: Object, filteredConfigs: Object } => {
  const query = window.location.search.slice(1);
  const vars = query.split('&');
  const defaultResults = {
    product: '',
    selections: {},
    filteredConfigs: {},
  };
  let results = {};
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    const decodedKey = decodeURIComponent(pair[0]);
    if (['product', 'selections'].includes(decodedKey)) {
      let decodedValue = decodeURIComponent(pair[1]);
      if ('selections' === decodedKey) {
        decodedValue = decodedValue
          .split(',')
          .reduce((selections, selection) => {
            const accConfigs = { ...selections };
            const [key, value] = selection.split(':');
            accConfigs[key] = value;
            return accConfigs;
          }, {});
      }
      // $FlowFixMe
      results[decodedKey] = decodedValue;
    }
  }
  const validProduct = results.product && products.includes(results.product);
  const { filteredConfigs } = filterConfigOptions({
    product: validProduct ? results.product : defaultProduct || products[0],
    configs,
  });
  // Verify results
  if (!results.product) {
    results = {};
    if (results.selections) {
      alert(
        __('Data provided via URL was not parsed, due to missing product!')
      );
    }
  } else if (!validProduct) {
    alert(__('Product provided via URL was incorrect!'));
    results = {};
  } else if (results.selections && Object.keys(results.selections).length) {
    const valid = Object.entries(filteredConfigs || []).every(
      ([configKey, configValue]) => {
        // $FlowFixMe
        const optionKeys = Object.keys(configValue.options);
        if (!results.selections[configKey]) {
          const [defaultOption] = optionKeys;
          results.selections[configKey] = defaultOption;
          return defaultOption;
        }
        return optionKeys.includes(results.selections[configKey]);
      }
    );
    if (!valid) {
      alert(__('Selections provided via URL was incorrect!'));
      results = { ...results, selections: {} };
    }
  }

  return { ...defaultResults, ...results, filteredConfigs };
};
