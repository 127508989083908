// @flow
import React from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const loadingAnimation = keyframes`
  0% { 
    transform: scale(0);
    opacity: 1;
  } 100% {
    transform: scale(1.0);
    opacity: 0;
  }
`;

const Container = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ loading }) => (loading ? 1 : 0)};
  margin: 0;
  padding: 0;
  transition: ${({ loading }) =>
    loading
      ? 'opacity 1s cubic-bezier(1, 0, 0.75, 0.75)'
      : 'opacity 0.5s ease-in'};
  &::after {
    content: '';
    display: block;
    width: 4rem;
    height: 4rem;
    background-color: #fff;
    border-radius: 100%;
    animation: ${loadingAnimation} 1s infinite ease-in-out;
  }
`;

const Loader = ({ loading }: { loading: boolean }) => (
  <Container loading={loading} />
);

export default Loader;
