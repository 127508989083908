// @flow
import React, { Component } from 'react';
import styled from '@emotion/styled';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { __, pxToRem } from './helpers';
import { color, breakpoint } from './constants';

const Container = styled('div')`
  position: relative;
  display: flex;
  align-items: baseline;
  margin-bottom: 0.5rem;
  @media only screen and (min-width: ${breakpoint.md}) {
    max-width: ${pxToRem(240)};
    margin-bottom: 2.5rem;
  }
`;

const Title = styled('span')`
  margin-right: 0.25rem;
  font-size: ${pxToRem(36)};
  font-weight: 300;
`;

const SelectArea = styled('div')`
  position: relative;
  margin-left: auto;
  & > .CustomMuiFormControl {
    position: absolute;
    left: 0;
    right: 0;
    visibility: hidden;
  }
`;

const Input = styled('input')`
  margin: 0;
  padding: 0;
  font-size: ${pxToRem(18)};
  border: none;
  border-bottom: 1px solid ${color.brandPrimary};
  background: transparent;
  cursor: pointer;
  outline: none;
  &:focus {
    border-bottom: 1px solid ${color.beige};
  }
`;

class ChangeProduct extends Component<
  {
    products: Array<string>,
    currentValue: string,
    handleChange: Function,
  },
  { selectOpen: boolean }
> {
  state = {
    selectOpen: false,
  };

  handleClose = () => {
    this.setState({ selectOpen: false });
  };

  handleOpen = () => {
    this.setState({ selectOpen: true });
  };

  render() {
    const { products, currentValue, handleChange } = this.props;
    const { selectOpen } = this.state;
    return (
      <Container>
        <Title>{currentValue}</Title>
        <SelectArea>
          <Input
            type="button"
            value={__('change product')}
            onClick={this.handleOpen}
          />
          <FormControl classes={{ root: 'CustomMuiFormControl' }}>
            <Select
              value={currentValue}
              open={selectOpen}
              onClose={this.handleClose}
              onOpen={this.handleOpen}
              onChange={({ target }) => handleChange(target)}
              name="product"
            >
              {products.map(product => (
                <MenuItem key={product} value={product}>
                  {product}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </SelectArea>
      </Container>
    );
  }
}

export default ChangeProduct;
