import React from 'react';

const SliderArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#000"
      strokeWidth="1"
      transform="translate(-760 -448)"
    >
      <path
        d="M760.5 495.5h47v-47h-47v47z"
        transform="rotate(-180 784 472)"
      ></path>
      <path
        d="M791 483L779.662 471.661 791 460.323"
        transform="rotate(-180 785.331 471.661)"
      ></path>
    </g>
  </svg>
);

export default SliderArrow;
