// @flow
import React from 'react';
import ReactDOM from 'react-dom';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// import './index.css'; // For development only
import './material-ui.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { configs, color, products } from './constants';
// import * as serviceWorker from './serviceWorker';

import App from './App';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: color.beige,
      main: color.beige,
      dark: color.beige,
    },
  },
  typography: {
    useNextVariants: true,
    // Dev should have this on their machine. Exists in module parent project.
    fontFamily: '"Scto Grotesk A", Arial, sans-serif',
  },
});

const root = document.getElementById('module-configurator-root');

if (root instanceof Element) {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <App
        products={products}
        configs={configs}
        defaultProduct={root.getAttribute('data-product')}
      />
    </MuiThemeProvider>,
    root
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
