import React, { Component } from 'react';
import styled from '@emotion/styled';

import { __ } from './helpers';

import A from './A';

let mailto = process.env.REACT_APP_MAILTO;
try {
  mailto = JSON.parse(mailto);
  mailto.email = __(mailto.email);
  mailto.subject = __(mailto.subject);
  mailto.body = __(mailto.body);
  mailto.footer = __(mailto.footer);
} catch (e) {
  mailto = {};
  console.error(__('Failed to parse mailto JSON!'));
}
mailto = {
  email: '',
  subject: '',
  body: '',
  footer: '',
  ...mailto,
};

const Container = styled('div')``;

class AskForQuote extends Component<{ url: string }, { mailtoHref: string }> {
  state = { mailtoHref: '' };

  componentDidUpdate(prevProps) {
    const { url } = this.props;
    const { url: prevUrl } = prevProps;
    if (url !== prevUrl) {
      this.updateMailtoHref(url);
    }
  }

  updateMailtoHref(rawUrl) {
    const url = encodeURIComponent(rawUrl);
    const subject = encodeURIComponent(mailto.subject);
    const body = encodeURIComponent(mailto.body);
    const spacer = encodeURIComponent('\n\n');
    const footer = encodeURIComponent(mailto.footer);
    const mailtoHref = `mailto:${
      mailto.email
    }?subject=${subject}&body=${body}${spacer}${url}${spacer}${footer}`;
    this.setState({ mailtoHref });
  }

  render() {
    const { mailtoHref } = this.state;
    return (
      <Container>
        <A href={mailtoHref} target="_blank">
          {__('Ask for quote')}
        </A>
      </Container>
    );
  }
}

export default AskForQuote;
