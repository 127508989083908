// @flow
import * as React from 'react';
import styled from '@emotion/styled';

import { breakpoint, color } from './constants';
import { __, remToMobile } from './helpers';

import A from './A';

const OpenMobileConfigs = styled('div')`
  margin-top: ${remToMobile(1.5)};
  margin-bottom: ${remToMobile(2)};
  text-align: left;
  @media only screen and (min-width: ${breakpoint.md}) {
    display: none;
  }
`;

export default ({ handleClick = null }: { handleClick: Function }) => (
  <OpenMobileConfigs>
    <A textColor={color.brandSecondary} handleClick={handleClick}>
      {__('Options')}
    </A>
  </OpenMobileConfigs>
);
