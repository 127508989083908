/* eslint-disable flowtype/no-types-missing-file-annotation */
import * as React from 'react';
import styled from '@emotion/styled';

import { color } from './constants';
import { pxToRem } from './helpers';

const Container = styled('svg')`
  polygon,
  path {
    fill: ${({ fillColor }) => fillColor};
  }
  width: ${({ down }) => pxToRem(down ? 14 : 23)};
  height: ${({ down }) => pxToRem(down ? 17 : 19)};
  transform: ${({ down }) =>
    down &&
    'rotateZ(90deg) rotateX(180deg) translateY(-0.5rem) translateX(0.1rem) scale(1.6)'};
`;

const Arrow = ({
  color: fillColor,
  down,
}: {
  color?: string,
  down?: boolean,
}) =>
  !down ? (
    <Container
      width="23px"
      height="19px"
      viewBox="0 0 23 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fillColor={fillColor}
    >
      <polygon
        points="13.4536133 14.8701172 1 14.8701172 0.5 14.8701172 0.5 14.3701172 0.5 1 0.5 0.5 1.5 0.5 1.5 1 1.5 13.8701172 13.4536133 13.8701172 13.4536133 9.87011719 22.4536133 14.3701172 13.4536133 18.8701172"
      />
    </Container>
  ) : (
    <Container
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="14px"
      height="17px"
      viewBox="-2 1 22 17"
      version="1.1"
      fillColor={fillColor}
      down
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CTA-button-close---xs"
          transform="translate(-17.000000, -14.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <path
            id="Line-2"
            d="M26,32 L19.5,32 L19,32 L19,31.5 L19,13.5 L19,13 L20,13 L20,13.5 L20,31 L26,31 L26,27 L35,31.5 L26,36 L26,32 Z"
          />
        </g>
      </g>
    </Container>
  );
Arrow.defaultProps = {
  color: color.brandPrimary,
  down: false,
};

export default Arrow;
