// @flow
import { __ } from './helpers';

export const remBaselinePx = 16;
export const remMobilePx = 12;

const brandPrimary = '#000000';
const brandSecondary = '#ffffff';
const oak = '#b3a17d';
export const color = {
  brandPrimary,
  brandSecondary,
  jaffa: '#ee8031',
  falcon: '#856b7b',
  catalinaBlue: '#06258c',
  mongoose: '#b3a17d',
  shadow: '#856a4b',
  gothic: '#73929d',
  jungleMist: '#bcccd9',
  ceruleanBlue: '#2355b0',
  beige: '#bfaf9b',
  // Configurator specific:
  white: brandSecondary,
  black: brandPrimary,
  lightGray: '#d3d3d3',
  darkGray: '#a9a9a9',
  oakWhite: [oak, brandSecondary],
  oakBlack: [oak, brandPrimary],
};

export const breakpoint = {
  md: '1280px',
};

export const products = (() => {
  let parsedProducts = process.env.REACT_APP_PRODUCTS;
  try {
    parsedProducts = JSON.parse(parsedProducts);
  } catch (e) {
    parsedProducts = [];
    alert(`${__('Failed to parse products JSON!')} ${e.message}`);
  }
  return parsedProducts;
})();

/**
 * Generate configs object from env.
 */
export const configs = (() => {
  let parsedConfigs = process.env.REACT_APP_CONFIGS;
  try {
    // Parse and translate configs
    parsedConfigs = JSON.parse(parsedConfigs);
    (Object.values(parsedConfigs) || []).forEach((config) => {
      config.title = __(config.title); // eslint-disable-line no-param-reassign
      (Object.entries(config.options) || []).forEach(
        ([optionKey, optionValue]) => {
          config.options[optionKey] = __(optionValue); // eslint-disable-line no-param-reassign
        }
      );
    });
  } catch (e) {
    parsedConfigs = {};
    alert(`${__('Failed to parse configs JSON!')} ${e.message}`);
  }
  return parsedConfigs;
})();

export const imgBankUrl = process.env.REACT_APP_IMAGE_BANK_URL || '';
if (!imgBankUrl) {
  alert(__('Image bank url is missing!'));
}
