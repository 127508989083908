import styled from '@emotion/styled';

import { color, breakpoint } from './constants';
import { remToMobile } from './helpers';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: ${1280 - 16}px;
  margin: 0 auto;
  @media only screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
  }
`;
// NOTE: max-width is based on the width of the Right component + its padding
export const Left = styled('div')`
  flex: 0 1 0%;
  @media only screen and (min-width: ${breakpoint.md}) {
    flex-basis: 824px;
    max-width: calc(100% - 323px + 4rem);
  }
`;

export const Right = styled('div')`
  flex: 1 1 0%;
  order: -1;
  padding-top: 2rem;
  @media only screen and (min-width: ${breakpoint.md}) {
    flex: -1 0 323px;
    order: 0;
    margin-left: auto;
    padding-left: 4rem;
    padding-right: 4rem;
    box-sizing: border-box;
  }
  @media only screen and (min-width: 950px) {
    // flex-basis: 448px;
  }
`;

export const Configs = styled('div')`
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${({ mobileOpen }) => (mobileOpen ? 'block' : 'none')};
  padding: 87px ${remToMobile(1.5)} 5rem ${remToMobile(1.5)};
  overflow-y: scroll;
  background: ${color.brandSecondary};
  @media only screen and (min-width: ${breakpoint.md}) {
    position: relative;
    z-index: 0;
    display: block;
    padding: 0;
    background: transparent;
  }
`;

export const ProductImages = styled('div')`
  position: relative;
  padding-top: 80%;
  background-color: ${color.beige};
`;

export const Bottom = styled('div')`
  margin-top: 0.5rem;
  padding: 2rem;
  background-color: #bcccd9;
  .wp-block-kehittamo-block-form {
    padding: 0.5rem 0 2rem;
    &__container {
      flex-direction: column;
    }
    &__info,
    &__form {
      width: auto;
      input,
      textarea {
        color: ${color.brandPrimary};
        border-color: ${color.brandPrimary};
        &[type='submit'] {
          filter: brightness(0);
        }
      }
    }
  }
`;

export const SliderContainer = styled('div')`
  position: relative;

  .slick-slider {
    max-width: 100%;
  }

  .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;
  }

  @media only screen and (min-width: ${breakpoint.md}) {
    .slick-slider {
      width: 64vw;
    }
  }

  svg:hover {
    background: #ffffff6e;
  }

  .slick-next {
    right: 12px;
  }

  .slick-prev {
    left: 11px;
    z-index: 1;

    svg {
      transform: rotate(180deg);
    }
  }

  .slick-next:before,
  .slick-prev:before {
    content: none;
  }

  @media only screen and (max-width: ${breakpoint.md}) {
    svg {
      width: 35px;
      height: 35px;
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      right: 0;
    }
  }
`;
