// @flow
import React from 'react';
import styled from '@emotion/styled';

import { color, breakpoint } from './constants';
import { __, remToMobile } from './helpers';

import A from './A';

const Container = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-bottom: ${remToMobile(0.5)};
  padding-left: ${remToMobile(0.5)};
  padding-right: ${remToMobile(0.5)};
  box-shadow: 0 -1rem 4rem 1rem ${color.brandSecondary};
  background-color: ${color.brandSecondary};
  @media only screen and (min-width: ${breakpoint.md}) {
    display: none;
  }
`;

export default ({
  resetSelectionsToDefault,
  toggleMobileConfigs,
}: {
  resetSelectionsToDefault: Function,
  toggleMobileConfigs: Function,
}) => (
  <Container>
    <A handleClick={resetSelectionsToDefault}>{__('Reset')}</A>
    <A
      textColor={color.brandSecondary}
      style={{ marginLeft: 'auto' }}
      handleClick={toggleMobileConfigs}
      arrowDown
    >
      {__('Close')}
    </A>
  </Container>
);
