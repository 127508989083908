// @flow
import React from 'react';
import styled from '@emotion/styled';

import { color } from './constants';

const ArrowSmall = styled('svg')`
  position: absolute;
  top: 50%;
  right: 0.8rem;
  width: 1rem;
  height: 1rem;
  transform: translateY(-30%) rotateZ(${({ rotate }) => (rotate ? 360 : 90)}deg)
    rotateX(180deg);
  pointer-events: none;
  transition: transform 0.5s;
`;

export default ({
  disabled,
  rotate,
}: {
  disabled: boolean,
  rotate: boolean,
}) => (
  <ArrowSmall
    width="16px"
    height="16px"
    viewBox="-2 2 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    rotate={rotate}
  >
    <title>ArrowSmall</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="dropdown-menu"
        transform="translate(-169.000000, -18.000000)"
        fill={disabled ? color.darkGray : color.brandPrimary}
        fillRule="nonzero"
      >
        <path
          id="Line-2"
          d="M173.5,31.5 L168,31.5 L167.5,31.5 L167.5,31 L167.5,21 L167.5,20.5 L168.5,20.5 L168.5,21 L168.5,30.5 L173.5,30.5 L173.5,26.5 L182.5,31 L173.5,35.5 L173.5,31.5 Z"
        />
      </g>
    </g>
  </ArrowSmall>
);
